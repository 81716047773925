@media screen and (max-width:1127px) {
    .admin-bar{
        .mobileNavs{
            top: 150px !important;
        }
    }
}
@media screen and (max-width:447px) {
    .admin-bar{
        .mobileNavs{
            top: 129px !important;
        }
    }
}
.link-out{
    position:absolute;
    right:30px;
    top:30px;
    z-index:100;
    img{
        width:160px;
    }
}
pre{
    width: 100%;
}
%ul.default{
    margin: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    li{
        list-style: none;
    }
}
.blocks-gallery-grid{
    padding-left: 0px !important;
}
figcaption{
    overflow: visible !important;
}
figure{
    min-height: 30px !important;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.wp-block-gallery{
    padding-left: 0px !important;
    li{
        margin-bottom: 60px !important;
        figure{
            flex-wrap: wrap;
            a{
                min-width: 100%;
                img{
                    min-width: 100% !important;
                    display: block !important;
                }
            }
            figcaption{
                background: none !important;
                width: 100%;
                color: #aaa8a6 !important;
                line-height: 18px !important;
                padding: 10px 0px 0px 0px !important;
                font-weight: bold !important;
                //flex: 0 0 100% !important;
                position: static !important;
            }
        }
    }
}
.admin-bar{
    #header.fixed{
        margin-top: 32px;
        z-index: 999;
    }
}
.wp-block-image{
    margin-top: 60px;
    margin-bottom: 60px;
    width: 100%;
    display: block;
    img{
        width: 100%;
        height: auto;
    }
}

%pull-left{
    float: left;
}
%pull-right{
    float: right;
}
pre{
    white-space: normal;
    color: #8DC296;
    overflow: visible!important;
    margin-top: 30px;
    margin-bottom: 30px;
    code{
        font-family: 'Quicksand', sans-serif;
        font-size: 17px;
        border-top: 1px solid #8DC296;
        border-bottom: 1px solid #8DC296;
        padding: 30px 0px 30px 0px;
        color: #8DC296;
        display: block;
        position: relative;
    }
}
.ginput_container.ginput_container_fileupload{
    margin-top: 30px;
}
.top{
    cursor: pointer;
    position: fixed;
    bottom: 40px;
    right: 54px;
    z-index: 998;
    display: none;
    &.active{
        display: block;
    }
    img{
        width: 43px;
    }
}

.btn{
    border: 1px solid #58C97B;
    font-size: 12px;
    text-decoration: none;
    color: #58C97B;
    border-radius: 4px;
    -webkit-transition: background-color .5s;
    transition: background-color .5s;
    position: relative;
    line-height: 44px;
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    text-align: center;
    &:hover{
        background-color: #58C97B;
        color: white;
    }
    i{
        margin-right: 10px;
        font-size: 18px;
        position: relative;
        top: 3px;
    }
}
.toptext{
    color: #AAA8A6;
    font-size: 30px;
    line-height: 50px;
    margin-top: 120px;
    @media screen and (max-width:949px) {
        margin-top: 100px;
    }
}

.btn-back{
    margin-top: 40px;
    .row{
        padding-left: 15px;
        padding-right: 15px;
        .column{
            border-top: 1px solid #E0E0E0;
            border-bottom: 1px solid #E0E0E0;
            text-align: center;
            padding: 25px 0px 25px 0px;
            a{
                color: #58C97B;
                text-decoration: none;
                -webkit-transition: opacity .4s;
                transition: opacity .4s;
                &:hover{
                    opacity: .7;
                }
            }
        }
    }
}
a{
    cursor: pointer;
}
.btn-link,.btn-link-text{
    color: #58C97B;
    text-decoration: none;
    line-height: 44px;
    i{
        font-size: 26px;
        position: absolute;
        margin-top: 10px;
    }
    span{
        //float: left;
        padding-bottom: 10px;
        padding-top: 4px;
        margin-left: 40px;
        opacity: 1;
        -webkit-transition: opacity .4s;
        transition: opacity .4s;
    }
    &.btn-link-text{
        span{
            margin-left: 0px;
        }
    }
    &:hover{
        span{
            opacity: .7;
        }
    }
}
%img-responsive{
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
}
html,body{
    overflow-x: hidden !important;
}
body{
    font-family: 'Quicksand', sans-serif;
    font-size: 15px;
    line-height: 26px;
    &.locked{
        max-height: 100vh;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        position: fixed;
        #headline{
            display: none;
        }
    }
}
hr{
    border: none;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 30px;
    margin-top: 30px;
}
.bgs{
    background-image: url(../img/bgs.svg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top;
}
#headline{
    background-color:#F8F6F4;
    font-size: 11px;
    text-transform: uppercase;
    .column{
        text-align: right;
        ul{
            @extend %ul.default;
            padding: 15px 0px 15px 0px;
            display: inline-block;
            li{
                display: inline-block;
                margin-left: 5px;
                margin-right: 5px;
                a{
                    color: #7A7A7A;
                    text-decoration: none;
                    display: block;
                    letter-spacing:1px;
                    i{
                        font-size: 15px;
                    }
                    &:hover{
                        color: black;
                    }
                }
            }
            &.lang{
                border-left: 1px solid #E0E0E0;
                border-right: 1px solid #E0E0E0;
                padding: 15px 15px 15px 15px;
                margin: 0px 15px 0px 15px;
            }
            &.social{
                padding-right: 23px;
                li:last-of-type{
                    margin-right: 0px;
                }
            }
            &:first-of-type{
                li{
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
    @media screen and (max-width:515px) {
        &{
            display: none;
        }
    }
}
#header{
    .column{
        position: relative;
        .mobileNavs{
            display: none;
        }
        .mobileNav{
            display: none;
            cursor: pointer;
        }
        .logo{
            @extend %pull-left;
            width: 180px;
            margin-top: 28px;
            img{
                max-width: 180px;
                display: block;
            }
        }
        ul{
            @extend %ul.default;
            @extend %pull-right;
            &:hover{
                li{
                    a{
                        opacity: .4;
                    }
                }
            }
            li{
                display: inline-block;
                &:hover{
                    a{
                        opacity: 1;
                    }
                }
                a{
                    color: black;
                    font-size: 15px;
                    text-decoration: none;
                    padding: 26px 23px 26px 23px;
                    display: block;
                    -webkit-transition: opacity .3s;
                    transition: opacity .3s;
                }
                &.active{
                    a{
                        color: #58c97b;
                    }
                }
                @media screen and (max-width:1348px) {
                    &{
                        a{
                            padding: 26px 10px 26px 10px;
                        }
                    }
                }
            }
        }
        @media screen and (max-width:1277px) {
            &{
                padding-top: 0px;
                padding-bottom: 36px;
            }
            ul{
                display: none;
            }
            .mobileNav{
                display: block;
                position: absolute;
                right: 15px;
                top: 27px;
                font-size: 30px;
                color: #303030;
            }
            .mobileNavs{
                display: none;
                &.active{
                    position: fixed;
                    top: 50px;
                    margin: 0;
                    left: 0;
                    width: 100%;
                    background-color: #fff;
                    z-index: 997;
                    min-height: 100vh;
                    -webkit-padding-start: 0;
                    overflow-x: scroll;
                    overflow-y: scroll;
                    height: 100%;
                    padding-bottom: 200px;
                    display: block !important;
                }
                li{
                    display: block;
                    border-bottom: 1px solid #d5d3d4;
                    &:last-of-type{
                        border: none;
                    }
                    a{
                        padding: 21px 30px 21px 15px !important;
                        &::after{
                            content: "\e912";
                            font-family: 'iconpack';
                            position: absolute;
                            right: 22px;
                            color: #AAA8A6;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        @media screen and (max-width:447px) {
            .mobileNavs{
                //top: 80px !important;
            }
        }
        @media screen and (max-width:639px) {
            &{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    &.fixed{
        position: fixed;
        z-index: 1000;
        top: 0px;
        background-color: white;
        width: 100%;
        height: 53px;
        //overflow: hidden;
        .column{
            ul{
                display: none;
            }
            ul.mobileNavs{
                @extend %ul.default;
                @extend %pull-right;
                display: block;
                @media screen and (max-width:1277px) {
                    &{
                        display: none;
                    }
                }
                li{
                    position: relative;
                    a{
                        font-size: 13px;
                        padding: 13px 8px 15px 8px;
                    }
                    &.menu-item-ceny-a-katalog,&.menu-item-poptat-projekt,&.active{
                        a{
                            color: #58c97b;
                        }
                    }
                    &.menu-item-ceny-a-katalog::before{
                        content: "";
                        display: block;
                        width: 2px;
                        height: 40px;
                        margin-top: 7px;
                        background-color: #F8F6F4;
                        position: absolute;
                        left: -5px;
                    }

                }
            }
            .logo{
                margin-top: 15px;
            }
        }
        @media screen and (max-width:1127px) {
            .mobileNav{
                top: 12px;
            }
        }
    }
}
#headImg{
    position: relative;
    overflow: hidden;
    .column{
        position: relative;
    }
    .text{
        position: absolute;
        width: 100%;
        bottom: 35%;
        z-index: 100;
        span{
            background-color: white;
            padding: 0px 15px 0px 15px;
            color: black;
            font-weight: bold;
            position: relative;
        }
        h1{
            font-size: 48px;
            line-height: 56px;
        }
    }
    img{
        @extend %img-responsive;
    }
    @media screen and (max-width:954px) {
        .text{
            bottom: 20%;
            h1{
                line-height: 52px;
                font-size: 42px;
                span{
                    padding: 0 14px 2px 14px;
                }
            }
        }
    }
    @media screen and (max-width:639px) {
        .column{
            padding: 0px;
        }
        /*
        .text{
            h1{
                font-size: 40px;
                line-height: 48px;
            }
        }
        */
    }
    @media screen and (max-width:469px) {
        .text{
            h1{
                line-height: 42px;
                font-size: 36px;

            }
        }
    }
    @media screen and (max-width:375px) {
        .text{
            h1{
                line-height: 36px;
                font-size: 30px;

            }
        }
    }
    @media screen and (max-width:337px) {
        .text{
            h1{
                line-height: 30px;
                font-size: 26px;

            }
        }
    }
    @media screen and (max-width:298px) {
        .text{
            h1{
                line-height: 28px;
                font-size: 20px;

            }
        }
    }
}
#slider{
    position: relative;
    &.loading{
        height: 100vh;
    }
    .owl-dots{
        position: absolute;
        right: 60px;
        bottom: 60px;
        div{
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: #5A5A5A;
            border-radius: 100%;
            margin-left: 20px;
            &.active{
                background-color: #58C97B;
            }
        }
        @media screen and (max-width:639px) {
            width: 100%;
            text-align: center;
            right: 0px;
            left: 0px;
            bottom: 30px;
        }
    }
    .item{
        max-height: 100vh;
        overflow: hidden;
        max-height: -webkit-calc(100vh - 154px);
        max-height:    -moz-calc(100vh - 154px);
        max-height:         calc(100vh - 154px);
    }
    @media screen and (max-width:639px) {
        .column{
            padding: 0px;
        }
    }
    .column{
        position: relative;
        .text{
            position: absolute;
            bottom: 60px;
            z-index: 990;
            margin-left: 60px;
            span{
                background-color: white;
                padding: 0px 15px 0px 15px;
                font-size: 48px;
                line-height: 56px;
                color: black;
                font-weight: bold;
                position: relative;
                &:last-of-type{
                    background-color: #58C97B;
                    color: white;
                    font-size: 16px;
                    line-height: 34px;
                    text-transform: uppercase;
                    font-weight: 400;
                }
            }
            @media screen and (max-width:639px) {
                &{
                    margin-left: 0px;
                    position: static;
                    margin-top: 30px;
                    bottom: auto;
                    padding: 0px 30px 0px 30px;
                    span{
                        font-size: 32px;
                        padding: 0px 15px 0px 0px;
                        line-height: 42px;
                        &:last-of-type{
                            font-size: 13px;
                            padding: 0px 15px 0px 10px;
                        }
                    }
                }
            }
        }
    }

}
#welcome-text{
    text-align: center;
    padding-top: 120px;
    //padding-bottom: 120px;
    .text4{
        p{
            margin-top: 40px;
        }
    }
    p{
        margin-bottom: 40px;
    }
    .text{
        color: #58C97B;
        font-size: 18px;
        text-align: center;
        display: flex;
        min-height: 150px;
        margin-bottom: 40px;
        justify-content:center;
        align-items:center;
        background-image: url(../img/welcomepage-title-bg.svg);
        background-repeat: no-repeat;
        background-position: center;
        span{
            margin-left: 20px;
            margin-right: 20px;
        }
        big{
            font-size: 70px;
        }
    }
}
.contact-head{
    background-color: #303030;
    padding-top: 100px;
    padding-bottom: 100px;
    color: white;
    line-height: 28px;
    h2{
        font-size: 12px;
        font-weight: normal;
        color: #7A7A7A;
        text-transform: uppercase;
    }
    a{
        color: #58C97B;
        text-decoration: none;
    }
    small{
        color: #7A7A7A;
        font-size: 12px;
    }
    p{
        margin: 0px;
        small{
            color: #7A7A7A;
            font-size: 12px;
            line-height: 16px !important;
            margin-top: 23px !important;
            display: block;
        }
    }
    a.phone,a.mail{
        font-size: 32px;
        font-weight: bold;
    }
    a.phone{
        color: white;
    }
    .row{
        .columns:first-of-type{
            a{
                margin-bottom: 25px;
                display: block;
            }
        }
    }
}
.youtube {
    margin-top: 60px;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    .resp-iframe,iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
.tags{
    border-radius: 6px;
    padding: 5px;
    border: 1px solid #AAA8A6;
    color: #AAA8A6;
    font-size: 12px;
    -webkit-transition: background-color .5s;
    transition: background-color .5s;
    &.active{
        border: 1px solid #58C97B;
        color: #58C97B;
    }
}
a.tags:hover{
    background-color:#AAA8A6;
    color: white;
}
a.tags.active:hover{
    background-color:#58C97B;
    color: white;
}
.article-detail{
    &.order{
        padding-top: 100px;
    }
    .wp-block-image{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    ul{
        padding-inline-start: 0px;
        padding-left: 18px;
    }
    img{
        @extend %img-responsive;
        margin-bottom: 20px;
    }
    a:not(.download-btn){
        -webkit-transition: opacity .4s;
        transition: opacity .4s;
        text-decoration: none;
    }
    a:not(.download-btn):hover{
        opacity: .7;
        text-decoration: underline;
    }
    /*
    pre{
        white-space: normal;
        color: #AAA8A6;
        font-size: 24px;
        line-height: 38px;
        overflow: visible !important;
        code{
            font-family: 'Poppins';
            padding: 60px 0px 60px 0px;
            background-color: #F8F7F5;
            display: block;
            position: relative;
            &::before{
                content: "";
                display:block;
                width: 100px;
                background-color: #F8F7F5;
                height: 100%;
                position: absolute;
                top: 0px;
                left: -100px;
                z-index: 900;
            }
            &::after{
                content: "";
                display:block;
                width: 100px;
                background-color: #F8F7F5;
                height: 100%;
                position: absolute;
                top: 0px;
                right: -100px;
            }
        }
    }
    */
    .columns{
        position: relative;
    }
    @media screen and (max-width:639px) {
        .columns{
            overflow: hidden;
            padding-left: 30px !important;
            padding-right: 30px !important;
        }
    }
    .tag{
        color: #aaa8a6;
    }
    p{
        margin-top: 1.6em;
        margin-bottom: 1.6em;
    }
}
.article-img{
    margin-bottom: 30px;
    img{
        @extend %img-responsive;
    }
}

.articles{
    .article{
        padding-top: 90px;
        padding-bottom: 90px;
        @media screen and (max-width:639px) {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        .wrapper{
            & > .row{
                & > .columns:not(.image){
                    padding-left: 114px;
                    @media screen and (max-width:1084px) {
                        padding-left: 40px;
                    }
                }
            }
        }
        &.withBg{
            background-color: #F8F7F5;
        }
        .tag{
            color: #303030;
            font-size: 12px;
            text-transform: uppercase;
            padding-top: 15px;
            display: block;
            letter-spacing:2px;
        }
        p{

        }
        a{
            display: inline-block;
            margin-top:25px;
            cursor: pointer;
        }
        big{
            font-size: 22px;
            font-weight: bold;
        }
        .image{
            margin-bottom: 20px;
            img{
                @extend %img-responsive;
            }
        }
        .hidden{
            //opacity: 0;
            //max-height: 0;
            //overflow: hidden;
            //clear: both;
            //-webkit-transition: all 0.2s ease 0.3s;
            //-o-transition: all 0.2s ease 0.3s;
            //transition: all 0.2s ease 0.3s;

            //max-height: 0px;
            display: none;
            .hideBtn{
                display: block;
                text-align: center;
                padding-top: 20px;
            }
        }
        &.active{
            .hidden{
                margin-top: 30px;
                display: block;
                opacity: 1;
                max-height: 100%;
                -webkit-transition: all 0.35s ease 0.15s;
                 -o-transition: all 0.35s ease 0.15s;
                 transition: all 0.35s ease 0.15s;
                 overflow: visible;
            }
            .js-hidden{
                display: none;
            }
            .hideBtn{
                a{
                    display: block !important
                }
            }
        }
        &:nth-child(even){
            background-color: #F8F7F5;
            .wrapper{
                .row{
                    flex-direction: row-reverse;
                }
            }
        }
        .wrapper{
            .row{
                flex-direction: row;
                &.reverse{
                    flex-direction: row-reverse;
                }
            }
        }

        @media screen and (max-width:639px) {
            .wrapper{
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .owl-carousel{
            margin-top: 60px;
        }
    }
}
.title{
    padding-top: 80px;
    padding-bottom: 25px;
    small{
        display: block;
        color: #303030;
        font-size: 12px;
    }
    h2{
        @extend %pull-left;
    }
    a{
        color: #58C97B;
        text-decoration: none;
    }
    a.btn{
        @extend %pull-right;
        margin-top: 20px;
    }
    &.text-center{
        text-align: center;
        h2{
            float: none;
        }
    }
}
.download-btn{
    color: #58C97B;
    border-radius: 4px;
    background-color: rgba(88, 201, 123, 0.15);
    width: 100%;
    display: block;
    text-decoration: none;
    text-align: center;
    margin-top: 38px;
    margin-bottom: 38px;
    padding: 38px 50px 38px 50px;
    position: relative;
    -webkit-transition: background-color .4s;
    transition: background-color .4s;
    i{
        font-size: 30px;
        position: absolute;
        left: 25px;
    }
    &:hover{
        background-color: #58C97B;
        color: white;
    }
}
.download{
    @extend %ul.default;
    li{
        padding-bottom: 10px;
        padding-top: 10px;
        border-right: 1px solid #E0E0E0;
        padding-left: 20px;
        a{
            padding-left: 40px;
            i{
                color: #58C97B;
                margin-right: 10px;
                font-size: 24px;
                position: absolute;
                margin-left: -40px;
            }
            color: black;
            text-decoration: none;
            span{
                color: #AAA8A6;
            }
            &:hover{
                color: #58C97B;
                span{
                    color: #AAA8A6;
                }
            }
        }
        &:nth-child(even){
            border-right: none;
        }
    }
}
.contactbtn{
    position: absolute;
    left: 0px;
    width: 100%;
    text-align: center;
    a{
        background-color: #58C97B;
        color: white;
    }
    @media screen and (max-width:639px) {
        &{
            margin-top: -60px;
        }
    }
}
.story{
    margin-top: 120px;
    position: relative;
    margin-bottom: 40px;
    &::before{
        content: "";
        width: 2px;
        height: 90%;
        background-color: #E0E0E0;
        position: absolute;
        display: flex;
        margin-left: -30px;
        margin-top: 10px;
    }
    .col{
        padding-bottom: 30px;
        cursor: pointer;
        h4{
            font-size: 22px;
            line-height: 32px;
            &::before{
                content: "";
                position: absolute;
                margin-left: -38px;
                margin-top: 5px;
                width: 18px;
                height: 18px;
                background-color: #E0E0E0;
                border-radius: 100%;
                border: 2px solid white;
            }
        }
        &:first-of-type{
            h4{
                margin-top: 0px !important;
            }
        }
        p{
            color: #8E8E8E;
            display: none;
        }
        i{
            position: absolute;
            margin-left: -100px;
            font-size: 40px;
            margin-top: -3px;
            color: #E0E0E0;
        }
        &.active{
            p{
                display: block;
            }
            h4{
                &::before{
                    width: 26px;
                    height: 26px;
                    margin-left: -42px;
                    background-color: #58C97B;
                }
            }
            i{
                color: #58C97B;
            }
        }
        &:last-of-type{
            padding-bottom: 0px;
            &::before{
                display: none;
            }
        }
    }
    @media screen and (max-width:639px) {
        &{
            margin-bottom: 150px;
            padding-left: 80px;
        }
    }
}
.company-value{
    margin-bottom: 120px;
    .col{
        background-color: #F8F7F5;
        margin-bottom: 15px;
        line-height: 26px;
        i{
            position: absolute;
            font-size: 20px;
            margin-left: 25px;
            margin-top: 35px;
        }
        p{
            margin: 0px;
            padding: 30px 20px 30px 72px;
            font-size: 17px;
            &.awr{
                display: none;
                padding: 0px 72px 30px 20px;
                color: #8E8E8E;
            }
        }
    }
    &.accordJs{
        .col{
            cursor: pointer;
            display: block;
            width: 100%;
            position: relative;
            i{
                top: -5px;
                width: 40px;
                height: 40px;
                position: absolute;
                font-size: 20px;
                right: 15px;
                margin-left: auto !important;
                display: block;
                transform: rotate(90deg);
                color: #58C97B;
                font-size: 25px;
            }
            p{
                margin: 0px;
                padding: 30px 72px 30px 20px;
                font-size: 17px;
                &.awr{
                    display: none;
                    padding: 0px 72px 30px 20px;
                    color: #8E8E8E;
                }
            }
            &.active{
                .awr{
                    display: block;
                }
            }
        }
    }
    @media screen and (max-width:639px) {
        &{
            padding-left: 30px;
            padding-right: 30px;
            .columns{
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
}

.owl-subpage{
    .item{
        padding-bottom: 80px;
    }

    .text{
        font-size: 15px;
        color: #AAA8A6;
        padding: 15px;
        position: absolute;
        bottom: 0px;
        z-index: 900;
    }
    &:hover{
        .owl-nav{
            display:block;
        }
    }
    .owl-nav{
        display: none;
        position: absolute;
        z-index: 890;
        width: 100%;
        top: 0px;
        .owl-prev,.owl-next{
            position: absolute;
            color: #61c17f;
            padding: 20px 25px 20px 25px;
            line-height: 40px;
            background-color: rgba(0,0,0,.8);
        }
        .owl-prev{
            width: 50%;
            left: 20px;
            height: 100vh;
            transform: rotate(-180deg);
            opacity: 0 !important
        }
        .owl-next{
            width: 50%;
            right: 20px;
            height: 100vh;
            opacity: 0 !important
        }
        @media screen and (max-width:639px) {
            &{
                display: none !important;
            }
        }
    }
    .owl-dots{
        position: absolute;
        margin-top: -130px;
        right: 20px;
        z-index: 900;
        width: 100%;
        text-align: center;
        div{
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: #5A5A5A;
            border-radius: 100%;
            margin-left: 20px;
            &.active{
                background-color: #58C97B;
            }
        }
        @media screen and (max-width:639px) {
            width: 100%;
            text-align: center;

        }
    }
}
.pager{
    .row{
        padding-left: 15px;
        padding-right: 15px;
        .column{
            border-top: 1px solid #E0E0E0;
            border-bottom: 1px solid #E0E0E0;
            text-align: center;
            padding: 25px 0px 25px 0px;
            a{
                color: #303030;
                text-decoration: none;
                i{
                    display: none;
                }
                &.disabled{
                    color: #E0E0E0;
                }
            }
            a:first-of-type{
                @extend %pull-left;
            }
            a:last-of-type{
                @extend %pull-right;
            }
            @media screen and (max-width:448px) {
                .wrapper{
                    padding-left: 0px;
                    padding-right: 0px;
                    span{
                        display: none;
                    }
                    a{
                        &:first-of-type{
                            transform: rotate(-180deg);
                        }
                        i{
                            display: block;
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
}
.blog-nav{
    .wrapper{
        padding-top: 15px;
        padding-bottom: 15px;
        .column{
            border-top: 1px solid #E0E0E0;
            border-bottom: 1px solid #E0E0E0;
            padding: 0px;
            text-align: center;
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-around;
            margin-right: .625rem;
            margin-left: .625rem;
            padding-top: 15px;
            padding-bottom: 15px;
            a{
                color: #303030;
                text-align: center;
                text-decoration: none;
                opacity: .4;
                -webkit-transition: opacity .3s;
                transition: opacity .3s;
                &.active{
                    opacity: 1;
                }
            }
            &:hover{
                a{
                    opacity: .4;
                    &:hover{
                        opacity: 1;
                    }
                }
            }
            @media screen and (max-width:639px) {
                &{
                    flex-wrap: wrap;
                    a{
                        width: calc(100%/3);
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
            @media screen and (max-width:450px) {
                &{
                    a{
                        width: calc(100%/2);
                    }
                }
            }
        }
    }
}
.blog{
    .blog-article{
        padding-top: 15px;
        padding-bottom: 15px;
        cursor: pointer;
        position: relative;
        &.star::before{
            content: "\e906";
            font-family: 'iconpack';
            position: absolute;
            color: #FFFFFF;
            z-index: 901;
            font-size: 30px;
            left: 20px;
            top:20px;
            text-shadow: 1px 1px 3px rgba(0,0,0,.4);

        }
        &:hover{
            div:first-of-type{
                img{
                    transform: scale(1.2);
                }
            }
        }
        div:first-of-type{
            overflow: hidden;
            position: relative;
            img{
                @extend %img-responsive;
                -webkit-transition: transform .5s;
                transition: transform .5s;
            }
        }
        div:last-of-type{
            padding: 20px;
            a{
                text-decoration: none;
            }
            a.bt{
                @extend %pull-right;
                font-size: 30px;
                text-decoration: none;
            }
            h3{
                margin: 0px 0px 11px 0px !important;
                padding: 0px;
                height: 50px;
                overflow: hidden;
                a{
                    float: none;
                    font-size: 16px;
                    line-height: 22px;
                    color: black;
                }
            }
            .time{
                padding: 0px;
                margin-bottom: 20px;
                font-size: 12px;
                color: #AAA8A6;

            }
            p{
                margin: 0px;
                padding: 10px 0px 10px 0px;
                font-size: 13px;
            }
        }
        &.blog-type{
            a{
                font-size: 26px;
            }
        }
        &.video{
            div:first-of-type{
                a::before{
                    content: "";
                    display: block;
                    position: absolute;
                    background-color: rgba(0,0,0,.5);
                    width: 100%;
                    height: 100%;
                    z-index: 900;
                }
                a::after{
                    font-family: 'iconpack' !important;
                    content: "\e900";
                    color: white;
                    position: absolute;
                    z-index: 901;
                    top: 0px;
                    font-size: 30px;
                    left: -webkit-calc(55% - 23px);
                    left:    -moz-calc(55% - 23px);
                    left:         calc(55% - 23px);

                    top: -webkit-calc(55% - 26px);
                    top:    -moz-calc(55% - 26px);
                    top:         calc(55% - 26px);
                }
            }
        }
    }
    &.blogPage{
        .blog-article{
            div:last-of-type{
                a{
                    float: none;
                }
            }
        }
    }
}
.wroteAbout{
    padding-left: 60px;
    padding-right: 60px;
    .bgs{
        padding-left: 15px;
        padding-right: 15px;
    }
    .row{
        background-color: #F8F7F5;
        &.expanded{
            padding-left: 0px;
        }
        div:first-of-type{
            padding-left: 0px;
            img{
                @extend %img-responsive;
            }
        }
        div:last-of-type{
            padding-left: 114px;
            p{
                color: #AAA8A6;
                font-size: 24px;
                line-height: 38px;
            }
            @media screen and (max-width:1021px) {
                &{
                    padding-left: 30px;
                }
            }
            @media screen and (max-width:639px) {
                &{
                    padding-bottom: 15px;
                    br{
                        display: none;
                    }
                    p{
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
        }
    }
    @media screen and (max-width:639px) {
        .bgs{
            padding-left: 0px;
            padding-right: 0px;
            .columns{
                padding-left: 30px !important;
                padding-right: 30px !important;
            }
        }
    }
    @media screen and (max-width:639px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}
#adr{
    padding-left: 75px;
    padding-right: 75px;
    margin-top: 120px;
    @media screen and (max-width:1367px) {
        &{
            padding-left: 50px;
            padding-right: 50px;
        }
    }
    @media screen and (max-width:1025px) {
        &{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .bgs{
        background-color: #F8F7F5;
        background-image: url(../img/banner-bg.svg);
        background-size: cover;
        .wrapper{
            padding-top: 157px;
            padding-bottom: 157px;
            .row{
                .columns{
                    margin-bottom: 25px;
                }
                div:first-of-type{
                    color: #303030;
                    h2{
                        color: white;
                    }
                    big{
                        font-size: 42px;
                        font-weight: bold;
                        span{
                            color: #58C97B;
                            font-weight: normal;
                        }
                    }
                    br{
                        display: none;
                    }
                }
                div:last-of-type{
                    text-align: center;
                    @media screen and (max-width:960px) {
                        &{
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
                @media screen and (max-width:575px) {
                    div:first-of-type{
                        br{
                            display: block;
                        }
                        big{
                            line-height: 50px;
                        }
                    }
                }
                @media screen and (max-width:960px) {
                    div{
                        flex:0 0 100% !important;
                        max-width: 100% !important;
                        text-align: left !important;
                    }
                }
            }
        }
    }
    &.subpage{
        .bgs{
            background: none;
            background-color: #61C17F;
            .row{
                .wrapper{
                    padding-top: 57px !important;
                    padding-bottom: 57px !important;
                    .columns{
                        margin-bottom: 0px;
                    }
                    .row{
                        div{
                            color: white;
                            a{
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:639px) {
        &{
            padding-left: 0px;
            padding-right: 0px;
            .bgs{
                .wrapper{
                    padding: 28px 30px 28px 30px;
                }
            }
        }
    }
}
#instagram{
    .columns{
        margin-bottom: 15px;
        img{
            @extend %img-responsive;
        }
    }
}
.contact{
    margin-bottom: 90px;
    .columns{
        margin-bottom: 25px;
        big{
            font-size: 26px;
            margin-bottom: 70px;
            display: block;
            padding-top: 10px;
            line-height: 36px;
        }
        p{
            font-size: 15px;
            a{
                color: #58C97B;
                text-decoration: none;
            }
        }
        img{
            @extend %img-responsive;
        }
        .row{
            flex-direction: row-reverse;
        }
    }
    .wrapper{
        & > .row:nth-child(even){
            flex-direction: row-reverse;
        }
    }
}
#form{
    margin-top: 90px;
    overflow: hidden;
    &.welcomePage{
        .titles{
            position: relative;
            width: 100%;
            &::before{
                content: "";
                width: 100%;
                border-bottom: 3px solid #58C97B;
                height: 3px;
                position: absolute;
                left: -105%;
                z-index: 9;
                bottom: 20px;
            }
            &::after{
                content: "";
                width: 200%;
                border-bottom: 3px dashed #58C97B;
                position: absolute;
                height: 3px;
                right:-100%;
                bottom: 20px;
                z-index: 9;
                background-color: white;
            }
            h3{
                background-color: white;
                position: relative;
                z-index: 10;
                display: inline;
                padding-right: 25px;
                &::before{
                    content: "\e901";
                    font-family: 'iconpack';
                    position: absolute;
                    bottom: -1px;
                    left: -23%;
                    font-size: 40px;
                    color: #58C97B;
                }
            }
        }
    }
    .titles{
        margin-bottom: 80px;
    }
    h3{
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 80px;
        span{
            color: #e7e4df;
        }
    }
    .validation_error{
        padding: 15px;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: 8px;
    }
    .validation_message{
        color: #721c24;
        font-size: 11px;
        margin-bottom: 15px;
    }
    .ginput_container_checkbox{
        .icheckbox_minimal-red{
            float: left;
            margin-right: 10px;
            margin-top: 3px;
            width: 20px;
        }
        label{
            position: static !important;
        }
    }
    .gform_wrapper{
        width: 100%;
        .gform_body{
            width: 100%;
            ul{
                @extend %ul.default;
                li{
                    width: 100%;
                    padding: 10px 0px 10px 0px;
                    label{
                        position: absolute;
                        z-index: 1;
                        -webkit-transition: margin-top .5s;
                        transition:margin-top .5s;
                        span{
                            display: none;
                        }
                    }
                    input[type="text"],input[type="email"]{
                        background: none;
                        border: none;
                        border-radius: 0px;
                        color: black;
                        border-bottom: 1px solid #AAA8A6;
                        padding: 10px 0px 10px 0px;
                        box-shadow:none;
                        position: relative;
                        z-index: 2;
                        -webkit-transition: border-bottom .5s;
                        transition:border-bottom .5s;
                        width: 100%;
                    }
                    &.focus{
                        label{
                            margin-top: -20px;
                            color: #58C97B;
                            font-weight: bold;
                        }
                        input[type="text"],input[type="email"]{
                            border-bottom: 1px solid #58C97B;
                        }
                    }
                }
            }
        }
        button,input[type="submit"]{
            width: 100%;
            border-radius: 4px;
            background-color: #58C97B;
            border: 1px solid #58C97B;
            color: white;
            font-weight: medium;
            padding: 15px;
            cursor: pointer;
            &:hover{
                background-color: #4cb16b;
            }
        }
        .gfield_error{
            input[type="text"],input[type="email"]{
                border-bottom: 1px solid #721c24 !important;
            }
        }
    }
    .form-group{
        padding: 10px 0px 10px 0px;
        label{
            position: absolute;
            z-index: 1;
            -webkit-transition: margin-top .5s;
            transition:margin-top .5s;
        }
        input[type="text"],input[type="email"]{
            background: none;
            border: none;
            border-radius: 0px;
            color: black;
            border-bottom: 1px solid #AAA8A6;
            padding: 10px 0px 10px 0px;
            box-shadow:none;
            position: relative;
            z-index: 2;
            -webkit-transition: border-bottom .5s;
            transition:border-bottom .5s;
        }
        &.focus{
            label{
                margin-top: -20px;
                color: #58C97B;
                font-weight: bold;
            }
            input[type="text"],input[type="email"]{
                border-bottom: 1px solid #58C97B;
            }
        }
        button{
            width: 100%;
            border-radius: 4px;
            background-color: #58C97B;
            border: 1px solid #58C97B;
            color: white;
            padding: 15px;
            cursor: pointer;
            &:hover{
                background-color: #4cb16b;
            }
        }
    }

    @media screen and (max-width:639px) {
        .columns{
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
.socials{
    color: #AAA8A6;
    padding-top: 25px;
    text-align: center;
    p{
        margin: 0px;
        margin-bottom: 20px;
    }
    a{
        display: inline-block;
        text-decoration: none;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border: 2px solid #58C97B;
        border-radius: 100%;
        font-size: 20px;
        margin-left: 5px;
        margin-right: 5px;
        -webkit-transition: all .5s;
        transition: all .5s;
        &:hover{
            color: black;
            border: 2px solid black;
        }
    }
}
#footer{
    margin-top: 110px;
    background-color: #F8F7F5;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #6c6c6b;
    a{
        img{
            max-width: 180px;
        }
    }
    .medium-2{
        margin-top: 53px;
    }
    a.toTop{
        display: block;
        text-align: right;
        color: #303030;
        text-decoration: none;
        i{
            position: relative;
            display: inline-block;
            transform: rotate(-90deg);
            margin-top: 6px;
            font-size: 12px;
        }
        span{
            display: inline-block;
            text-transform: uppercase;
            font-size: 12px;

            margin-left: 20px;
        }
    }
    h4{
        color: #6c6c6b;
        font-size: 15px;
        text-transform: uppercase;
    }
    ul{
        @extend %ul.default;
        li{
            line-height: 26px;
            font-size: 12px;
            a{
                color: #6c6c6b;
                text-decoration: none;
            }
            &:hover{
                opacity: 1;
            }
        }
    }
    @media screen and (max-width:639px) {
        .columns:nth-child(3),.columns:nth-child(4){
            display: none;
        }
    }
    @media screen and (max-width:280px) {
        .columns{
            width: 100% !important;
            max-width: 100% !important;
        }
    }
}
#copyright{
    padding-top: 21px;
    padding-bottom: 21px;
    color: #AAA8A6;
    font-size: 11px;
    span{
        margin-left: 30px;
    }
    a{
        color: #58C97B;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .icheckbox_minimal-red,
    .iradio_minimal-red {
        background-image: url(../img/red@2x.png);
    }
}
.icheckbox_minimal-red,
.iradio_minimal-red {
    background-color: white;
    background: url(../img/red.png) no-repeat;
}
.icheckbox_minimal-red{
    background-color: white;
}
@media screen and (max-width:600px) {
    #wpadminbar{
        position:fixed !important;
        top:0px !important;
    }
}
